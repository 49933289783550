.makeStyles-avatar-4 {
  background-color: #609426 !important;
}

.height-12 {
  height: 12px;
}

.login__card {
  width: 33%;
  max-width: 560px;
  transition: 0.3s;
  background-color: white;
  padding: 26px 24px;
  border-radius: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;
  box-shadow: 0px 4px 20px 20px #f5f5f6;
}

@media (max-width: 981px) {
  .login__card {
    width: 626px;
  }
}

@media (max-width: 640px) {
  .login__card {
    width: 85%;
  }
}

@media (max-width: 400px) {
  .login__card {
    max-width: 350px;
  }
}

.button-wraper {
  display: grid;
  place-items: center;
}
.auth__container {
  text-align: center;
  margin-top: 32px;
}
.error-message {
  padding: 8px;
  text-align: center;
  transition: 0.4;
}

.MuiPaper-root.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8.MuiGrid-grid-md-4.MuiPaper-elevation0 {
  margin-top: 26px;
  margin-bottom: 70px;
  display: grid;
  place-items: center;
  border-radius: 12px;
}

.MuiPaper-root.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8.MuiGrid-grid-md-4.MuiPaper-elevation {
  background-image: url(http://vistaconcepts.co/wp-content/uploads/2021/04/vista-concepts-logo.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.97);
  background-blend-mode: overlay;
}
