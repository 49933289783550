.body {
  padding-left: 24px;
  padding-top: 34px;
  width: calc(100% - 24px);
  height: calc(100vh - 94px);
  overflow-y: scroll;
  background: #f7f7f7;
}

a {
  color: var(--brand-color) !important;
  transition: 0.2s;
}

a:hover {
  color: #006200;
  text-decoration: none;
}

table.MuiTable-root td,
table.MuiTable-root td a {
  font-weight: bold;
  font-size: 13px !important;
}

table.MuiTable-root td {
  color: #333333 !important;
}

.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
  border-radius: 12px;
  box-shadow: none;
  margin-right: 24px;
  margin-top: 28px;
}

.MuiTableCell-root {
  border-bottom: 0;
}

.MuiFormControl-root.log__selectRoot {
  width: 300px;
  margin-left: 4px;
}
.MuiSelect-select.MuiSelect-select {
  padding: 8px;
}

.MuiSelect-select:focus {
  background-color: #f7f7f7 !important;
}
.MuiInputLabel-shrink {
  transform: translate(0, -5.5px) scale(0.75) !important;
}

.detailPanel {
  padding: 20px 60px;
  display: flex;
}

.detailPanel__item {
  display: flex;
}

.detailPanel__itemTitle {
  min-width: 80px;
  font-weight: 500;
}

.detailPanel__itemData {
  min-width: 80px;
  margin-right: 20px;
}

.detailPanel__itemTitle,
.detailPanel__itemData {
  padding-right: 20px;
}

button.MuiButtonBase-root.MuiIconButton-root.receipt__iconButton {
  font-size: 8px;
}

.body__toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-right: 24px;
}

@media (max-width: 600px) {
  .body__toolbar {
    flex-direction: column;
  }
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.Mui-disabled.Mui-disabled {
  background: #bcd4bd !important;
}

.invoiceInput__input {
  width: 100%;
}

.invoiceInput__input input {
  text-align: center;
  font-size: 24px;
}

.invoiceInput button {
  outline: none;
}

p.invoiceInput__paragraph {
  max-width: 250px;
  font-size: 14px;
  color: #6f6f6e;
}

.MuiTableCell-root {
  padding: 8px 10px !important;
}

.MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.5) !important;
  padding: 4px 10px;
  border-radius: 20px;
  font-weight: 500;
}

.MuiFormGroup-root.invoiceInput__radio {
  display: flex;
  flex-direction: row;
}

.u-badge {
  display: inline-flex;
  text-align: center;
  font-weight: bold;
  font-size: 11px;
  padding: 4px 8px;
  border-radius: 12px;
  min-width: 64px;
  justify-content: center;
}

.badge__admin {
  background: #ffdacf;
  color: #ff3c00;
}

.badge__nonAdmin {
  background: #e0e0e0;
  color: #868686;
}

.badge__client {
  background: #daffdc;
  color: #008104;
}

.badge__designer {
  background: #ffe8c6;
  color: #af6900;
}

.badge__rejected {
  background: #ffeaea;
  color: #ff4f4f;
}

.badge__waiting {
  background: #ffefe6;
  color: #ad5119;
}

.badge__finished {
  background: #cef9f5;
  color: #287390;
}

.badge__approved {
  background: #edffd9;
  color: #338036;
}

.projectDetail__dialog .MuiPaper-root {
  width: min(95%, 600px);
}

.projectDetailEdit__dialog .MuiPaper-root {
  width: min(95%, 850px);
}

.button__action {
  background: #edffd9 !important;
  color: #609426 !important;
}

.button__action:hover {
  background: #609426 !important;
  color: white !important;
}

.button__primary {
  background: #609426 !important;
  color: white !important;
}

.button__primary:hover {
  background: #edffd9 !important;
  color: #609426 !important;
}

.button__actionDanger {
  background: #ffeaea !important;
  color: #ff4f4f !important;
}

.button__actionDanger:hover {
  background: #e70909 !important;
  color: white !important;
}

.u-rowButton {
  padding: 4px !important;
  outline: none !important;
  margin: 1px !important;
}

.u-rowButton span.MuiButton-label {
  font-size: 11px !important;
  font-weight: bold !important;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.u-rowButton.button__action.Mui-disabled.Mui-disabled {
  background: #ebebeb !important;
  color: rgb(177, 177, 177) !important;
}

@media (max-width: 981px) {
  .u-hide-me-on-small-devices {
    display: none !important;
  }
}

.imageUploadedPreviewOnEditView:hover .imageRemoveButton {
  visibility: visible;
}

.imageUploadedPreviewOnEditView:hover {
  filter: brightness(0.8);
  visibility: visible;
}

.imageUploadedPreviewOnEditView {
  transition: 0.4s;
}

.imageRemoveButton {
  visibility: hidden;
}

span.fileExt {
  background: #ff9800;
  color: white;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 6px;
  font-size: 10px;
}

.u-switch {
  border: 1px solid #cacaca;
  border-radius: 6px;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.u-switchTab {
  cursor: pointer;
  width: 50%;
  text-align: center;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  transition: 0.4s;
}

.u-switchTab:hover {
  background-color: #fafafa;
}

.scrollable__fileType {
  height: 64px;
  width: 52px;
  background-color: #f9f9f9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border: 2px solid #b6bbb6;
  transition: 0.4s;
}

.scrollable__fileType:hover {
  background-color: #c5c5c5;
  transform: scale(0.9);
}

.body__toolbar
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  background: white;
  border-radius: 6px !important;
}

.body__toolbar
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl:before {
  opacity: 0;
}

.body__toolbar
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  z-index: 1;
  padding: 0 12px;
}

.body__toolbar .MuiSelect-select.MuiSelect-select {
  background: white;
  border-radius: 6px !important;
}

.uploaderView__backgroundLight {
  background: #fafafa;
}

.uploaderView__backgroundLight:hover {
  background: #d1ecd2;
}

.u-flex {
  display: flex;
  flex-direction: row;
}

@media (max-width: 824px) {
  .u-flex {
    flex-direction: column;
  }
}

.cgseTX {
  width: calc(100% - 120px) !important;
}

.newProjects__postButton {
  margin: 2px 24px 2px 2px !important;
}

@media (max-width: 600px) {
  .newProjects__postButton {
    margin: 24px 2px 4px 2px !important;
  }
}
