.dashboard__overview {
  display: grid;
  grid-gap: 0.6rem;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  margin: 8px 0 24px 0;
}

.dashboard__overviewCard {
  text-align: center;
  box-shadow: 2px 2px 10px 5px #f1f1f1;
  padding: 16px;
  border-radius: 8px;
  background: white;
  margin-right: 24px;
  margin-bottom: 24px;
}

.dashboard__overviewCard span {
  font-weight: bold;
  font-size: 2.2rem;
  line-height: 1.8;
  color: var(--brand-color);
}

.dashboard__overviewCard p {
  color: #585858;
}

.dashboard__timeLine {
  flex: 1;
  background: white;
  padding: 24px;
  border-radius: 8px;
  margin-right: 24px;
}

.dashboard__rejection {
  flex: 1;
  background: white;
  padding: 24px;
  border-radius: 8px;
  margin-right: 24px;
}

.dashboard__timeLineEvent {
  display: flex;
}

.dashboard__timelinePoint {
  width: 28px;
  min-height: 36px;
  position: relative;
}

.dashboard__timelineContent {
  padding-top: 6px;
}

.dashboard__timelineContent div {
  padding-left: 24px;
  color: #949494;
}

.dashboard__timelineContent div div span {
  line-height: 2;
}

.dashboard__timelinePointCircle {
  padding: 4px;
  border-radius: 50%;
  width: 2px;
  height: 2px;
  background: #bdc5b3;
  position: absolute;
  top: 12px;
}

.dashboard__timelinePointLine {
  height: 100%;
  width: 2px;
  background: #bdc5b3;
  position: relative;
  left: 4px;
}

.dashboard__statistics {
  display: flex;
  flex-direction: row;
}

@media (max-width: 700px) {
  .dashboard__statistics {
    flex-direction: column;
  }
  .dashboard__statistics .dashboard__timeLine,
  .dashboard__statistics .dashboard__rejection {
    margin-top: 32px;
  }
}

.dashboard__timlineH6 {
  color: var(--brand-color);
  font-weight: bold;
}

span.dashboard__counterHighlight {
  padding: 2px 12px;
  border-radius: 4px;
  font-size: 18px;
  margin-right: 12px;
}

.dashboard__payment {
  display: flex;
}

@media (max-width: 768px) {
  .dashboard__payment {
    flex-direction: column;
  }
}

.dashboard__paymentPricingCard {
  flex: 1;
  text-align: center;
  box-shadow: 2px 2px 10px 5px #f1f1f1;
  padding: 32px;
  border-radius: 8px;
  background: white;
  margin-right: 24px;
  margin-bottom: 24px;
}
.dashboard__paymentPriceAmount {
  font-size: 48px;
  color: var(--brand-color);
  font-weight: bold;
}
.dashboard__paymentDiscountAmount {
  color: #949494;
}

.dashboard__payButton {
  width: 70% !important;
}

.dashboard__continuePayment {
  width: min(450px, 90%) !important;
  display: inline !important;
}

.dashboard__paypalButtons {
  width: 55%;
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
}

.dashboard__table {
  width: 100%;
  border: 1px solid black !important;
}

.dashboard__table tbody {
  background-color: rgb(255, 255, 255);
}

.dashboard__table td {
  padding: 12px 32px;
  border: 1px solid rgb(216, 216, 216);
}

.dashboard__table tr:last-child td,
.dashboard__table tr:last-child td span {
  font-size: 18px;
  color: #609426;
  font-weight: bold;
}

.dashboard__table tr:last-child td {
  font-size: 14px;
  color: #3d3f3d;
  font-weight: bold;
}

.dashboard__table tr:last-child {
  background-color: #f1f9ef;
}

.dashboard__table td:nth-child(2) {
  text-align: center;
}

.circularProgressBar {
  display: grid;
  place-items: center;
  height: 100%;
}

.circularProgressBar .MuiTypography-colorTextSecondary {
  font-size: 24px;
  font-weight: bold;
  color: red;
}

.circularProgressBarCircle {
  border: 12px solid #fdfdfd !important;
  box-shadow: inset 0px 0px 0px 12px #cecece;
  color: #ff7575 !important;
  border-radius: 50%;
}

.bg-finished {
  color: white;
  background-color: #57bbbb;
}

.bg-approved {
  color: white;
  background-color: rgb(26, 177, 58);
}

.bg-queue {
  color: white;
  background-color: rgb(153, 0, 255);
}
