.header {
  z-index: 12;
  background: white;
  display: flex;
  height: 25px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.header__logo {
  padding-top: 25px;
  height: 90px;
  transition: 0.2s;
  filter: hue-rotate(25deg);
}

.header__logo:hover {
  transform: scale(1.1);
  filter: alpha(opacity);
  /* transform: rotate3d(0,1,0,360deg); */
}

.header__title {
  padding: 0px 12px;
}

.header__title h1 {
  padding-top: 6px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #727272;
}

.header__version {
  padding-top: 20px;
  font-size: 10px;
  color: #868686;
}

.header__left,
.header__right {
  display: flex;
  align-items: center;
}

.header__MenuIcon {
  margin-left: 12px !important;
  outline: none !important;
}

.header__loggedInfo {
  padding-left: 16px;
  padding-right: 16px;
}

.header__userName {
  font-size: 12px;
  word-wrap: break-word;
  margin-bottom: 0px;
  color: #333333;
}
.header__userText {
  font-size: 11px;
  word-wrap: break-word;
  margin-bottom: 0px;
  color: #676767;
}

.header__logout button {
  outline: none;
}

.header .MuiButton-text {
  color: var(--brand-color);
  padding: 18px 0px;
  border-radius: 50%;
}

.MuiMenu-paper {
  max-height: calc(100% - 96px);
  margin-top: 50px !important;
}

.small-avatar {
  width: 24px;
  height: 24px;
  text-align: center;
  color: var(--brand-color);
  background: var(--brand-color-rgba);
  border-radius: 50%;
}

.header__menuPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.header_subHead {
  font-weight: bold;
  margin-bottom: 8px;
  color: #6b6b6b;
  font-size: 16px;
}

.header__newNotification {
  margin: 12px 0px;
  background: #f1f1f1;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.4s;
}

.header__newNotification:hover {
  background: #d6d6d6;
  transform: scale(1.02);
}

@media (max-width: 768px) {
  .header__title,
  .header__version {
    display: none;
  }
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  padding: 8px 8px 0px;
}

.header__notification {
  padding: 8px 16px;
  max-width: 300px !important;
}

.header__notificationText {
  color: #333333;
  background: #f3f3f3;
  padding: 8px;
  border-radius: 8px;
  transition: 0.2s;
}

.header__notificationText:hover {
  color: white;
  transform: scale(1.025);
  background: #609426;
}

.header__notification h4 {
  font-weight: bold;
  margin-bottom: 16px;
}

.header__notificationRecord {
  display: flex;
}

.header__notificationRecordActive {
  width: 28px;
  height: 28px;
}

.header__notificationRecordActive p {
  width: 8px;
  height: 8px;
  background: green;
  border-radius: 50%;
  position: relative;
  top: 12px;
}

.notifyBall {
  width: 6px;
  height: 6px;
  background: red;
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 50%;
}

.noNotification {
  width: 300px;
  height: 100px;
  display: grid;
  place-items: center;
  background: #f7f7f7;
  border-radius: 8px;
}
