@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --brand-color: #609426;
  --brand-color-rgba: rgba(96, 148, 38, 0.3);
  --brand-text-color-dark: #3f6714;
}

* {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif !important;
  outline: none !important;
}

.App {
  display: flex;
  height: 100vh;
}

.app__body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.app__icon {
  color: #609426;
  color: var(--brand-color);
}

h2 {
  color: #333333 !important;
}

.centerLoader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.centerItem {
  display: grid;
  place-items: center;
  width: 100%;
  text-align: center;
}

*,
*::before,
*::after {
  box-sizing: unset !important;
}

.u-text {
  color: #777777;
}

.u-head-text {
  color: #3e3e3e;
}

.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary,
.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  outline: none;
  color: black;
  font-weight: 600;
  text-align: center;
  display: flex;
  margin: 0 auto;
  height: 32px;
  width: 180px;
}

.MuiButton-contained {
  background-color: #609426 !important;
  border-radius: 6px !important;
  color: #fff !important;
}

.MuiButton-outlined {
  border-radius: 6px !important;
  color: #609426 !important;
  font-weight: bold !important;
  border: 2px solid #609426;
}

.MuiFormLabel-root.Mui-focused {
  padding: 4px 0px !important;
}

.u-animate-fade {
  -webkit-animation: showfade 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s forwards 1;
          animation: showfade 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s forwards 1;
}

.u-animate-slideUp {
  -webkit-animation: showSlideUp 0.6s ease-in 0s forwards 1;
          animation: showSlideUp 0.6s ease-in 0s forwards 1;
}

.u-animate-slideDown {
  -webkit-animation: showSlideDown 0.6s ease-in 0s forwards 1;
          animation: showSlideDown 0.6s ease-in 0s forwards 1;
}

.u-animate-slideLeft {
  -webkit-animation: showSlideLeft 0.3s ease-in 0s forwards 1;
          animation: showSlideLeft 0.3s ease-in 0s forwards 1;
}

@-webkit-keyframes showSlideUp {
  0% {
    transform: translateY(3%);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes showSlideUp {
  0% {
    transform: translateY(3%);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes showSlideDown {
  0% {
    transform: translateY(-3%);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes showSlideDown {
  0% {
    transform: translateY(-3%);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes showSlideLeft {
  0% {
    transform: translateX(3%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes showSlideLeft {
  0% {
    transform: translateX(3%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes showfade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes showfade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dropzone:hover {
  background-size: 30px 30px;
  background-image: linear-gradient(
    -45deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  );
  -webkit-animation: stripes 2s linear infinite;
  animation: stripes 2s linear infinite !important;
}

@-webkit-keyframes stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 30px;
  }
}

@keyframes stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 30px;
  }
}

.ListTile {
  background: white;
  transition: 0.2s;
}

.ListTile:hover {
  background: #f9f9f9;
}

.u-small-dialog .MuiPaper-root {
  width: min(100%, 380px);
}

.u-medium-dialog .MuiPaper-root {
  width: min(100%, 500px);
}

.u-large-dialog .MuiPaper-root {
  width: min(100%, 600px);
}

.rowProject__Title {
  font-weight: bold;
  font-size: 12px;
  color: #609426;
  transition: 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.rowProject__Title span {
  font-size: 12px;
}

.rowProject__Title:hover {
  color: #426619;
}

.toast-header {
  color: white !important;
  background-color: #609426 !important;
}

#nprogress .bar {
  background: #609426 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #609426, 0 0 5px #609426 !important;
}

#nprogress .spinner-icon {
  border-top-color: #609426 !important;
  border-left-color: #609426 !important;
}

.body {
  padding-left: 24px;
  padding-top: 34px;
  width: calc(100% - 24px);
  height: calc(100vh - 94px);
  overflow-y: scroll;
  background: #f7f7f7;
}

a {
  color: var(--brand-color) !important;
  transition: 0.2s;
}

a:hover {
  color: #006200;
  text-decoration: none;
}

table.MuiTable-root td,
table.MuiTable-root td a {
  font-weight: bold;
  font-size: 13px !important;
}

table.MuiTable-root td {
  color: #333333 !important;
}

.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
  border-radius: 12px;
  box-shadow: none;
  margin-right: 24px;
  margin-top: 28px;
}

.MuiTableCell-root {
  border-bottom: 0;
}

.MuiFormControl-root.log__selectRoot {
  width: 300px;
  margin-left: 4px;
}
.MuiSelect-select.MuiSelect-select {
  padding: 8px;
}

.MuiSelect-select:focus {
  background-color: #f7f7f7 !important;
}
.MuiInputLabel-shrink {
  transform: translate(0, -5.5px) scale(0.75) !important;
}

.detailPanel {
  padding: 20px 60px;
  display: flex;
}

.detailPanel__item {
  display: flex;
}

.detailPanel__itemTitle {
  min-width: 80px;
  font-weight: 500;
}

.detailPanel__itemData {
  min-width: 80px;
  margin-right: 20px;
}

.detailPanel__itemTitle,
.detailPanel__itemData {
  padding-right: 20px;
}

button.MuiButtonBase-root.MuiIconButton-root.receipt__iconButton {
  font-size: 8px;
}

.body__toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-right: 24px;
}

@media (max-width: 600px) {
  .body__toolbar {
    flex-direction: column;
  }
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.Mui-disabled.Mui-disabled {
  background: #bcd4bd !important;
}

.invoiceInput__input {
  width: 100%;
}

.invoiceInput__input input {
  text-align: center;
  font-size: 24px;
}

.invoiceInput button {
  outline: none;
}

p.invoiceInput__paragraph {
  max-width: 250px;
  font-size: 14px;
  color: #6f6f6e;
}

.MuiTableCell-root {
  padding: 8px 10px !important;
}

.MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.5) !important;
  padding: 4px 10px;
  border-radius: 20px;
  font-weight: 500;
}

.MuiFormGroup-root.invoiceInput__radio {
  display: flex;
  flex-direction: row;
}

.u-badge {
  display: inline-flex;
  text-align: center;
  font-weight: bold;
  font-size: 11px;
  padding: 4px 8px;
  border-radius: 12px;
  min-width: 64px;
  justify-content: center;
}

.badge__admin {
  background: #ffdacf;
  color: #ff3c00;
}

.badge__nonAdmin {
  background: #e0e0e0;
  color: #868686;
}

.badge__client {
  background: #daffdc;
  color: #008104;
}

.badge__designer {
  background: #ffe8c6;
  color: #af6900;
}

.badge__rejected {
  background: #ffeaea;
  color: #ff4f4f;
}

.badge__waiting {
  background: #ffefe6;
  color: #ad5119;
}

.badge__finished {
  background: #cef9f5;
  color: #287390;
}

.badge__approved {
  background: #edffd9;
  color: #338036;
}

.projectDetail__dialog .MuiPaper-root {
  width: min(95%, 600px);
}

.projectDetailEdit__dialog .MuiPaper-root {
  width: min(95%, 850px);
}

.button__action {
  background: #edffd9 !important;
  color: #609426 !important;
}

.button__action:hover {
  background: #609426 !important;
  color: white !important;
}

.button__primary {
  background: #609426 !important;
  color: white !important;
}

.button__primary:hover {
  background: #edffd9 !important;
  color: #609426 !important;
}

.button__actionDanger {
  background: #ffeaea !important;
  color: #ff4f4f !important;
}

.button__actionDanger:hover {
  background: #e70909 !important;
  color: white !important;
}

.u-rowButton {
  padding: 4px !important;
  outline: none !important;
  margin: 1px !important;
}

.u-rowButton span.MuiButton-label {
  font-size: 11px !important;
  font-weight: bold !important;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.u-rowButton.button__action.Mui-disabled.Mui-disabled {
  background: #ebebeb !important;
  color: rgb(177, 177, 177) !important;
}

@media (max-width: 981px) {
  .u-hide-me-on-small-devices {
    display: none !important;
  }
}

.imageUploadedPreviewOnEditView:hover .imageRemoveButton {
  visibility: visible;
}

.imageUploadedPreviewOnEditView:hover {
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8);
  visibility: visible;
}

.imageUploadedPreviewOnEditView {
  transition: 0.4s;
}

.imageRemoveButton {
  visibility: hidden;
}

span.fileExt {
  background: #ff9800;
  color: white;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 6px;
  font-size: 10px;
}

.u-switch {
  border: 1px solid #cacaca;
  border-radius: 6px;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.u-switchTab {
  cursor: pointer;
  width: 50%;
  text-align: center;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  transition: 0.4s;
}

.u-switchTab:hover {
  background-color: #fafafa;
}

.scrollable__fileType {
  height: 64px;
  width: 52px;
  background-color: #f9f9f9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border: 2px solid #b6bbb6;
  transition: 0.4s;
}

.scrollable__fileType:hover {
  background-color: #c5c5c5;
  transform: scale(0.9);
}

.body__toolbar
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  background: white;
  border-radius: 6px !important;
}

.body__toolbar
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl:before {
  opacity: 0;
}

.body__toolbar
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  z-index: 1;
  padding: 0 12px;
}

.body__toolbar .MuiSelect-select.MuiSelect-select {
  background: white;
  border-radius: 6px !important;
}

.uploaderView__backgroundLight {
  background: #fafafa;
}

.uploaderView__backgroundLight:hover {
  background: #d1ecd2;
}

.u-flex {
  display: flex;
  flex-direction: row;
}

@media (max-width: 824px) {
  .u-flex {
    flex-direction: column;
  }
}

.cgseTX {
  width: calc(100% - 120px) !important;
}

.newProjects__postButton {
  margin: 2px 24px 2px 2px !important;
}

@media (max-width: 600px) {
  .newProjects__postButton {
    margin: 24px 2px 4px 2px !important;
  }
}

.header {
  z-index: 12;
  background: white;
  display: flex;
  height: 25px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.header__logo {
  padding-top: 25px;
  height: 90px;
  transition: 0.2s;
  -webkit-filter: hue-rotate(25deg);
          filter: hue-rotate(25deg);
}

.header__logo:hover {
  transform: scale(1.1);
  filter: alpha(opacity);
  /* transform: rotate3d(0,1,0,360deg); */
}

.header__title {
  padding: 0px 12px;
}

.header__title h1 {
  padding-top: 6px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #727272;
}

.header__version {
  padding-top: 20px;
  font-size: 10px;
  color: #868686;
}

.header__left,
.header__right {
  display: flex;
  align-items: center;
}

.header__MenuIcon {
  margin-left: 12px !important;
  outline: none !important;
}

.header__loggedInfo {
  padding-left: 16px;
  padding-right: 16px;
}

.header__userName {
  font-size: 12px;
  word-wrap: break-word;
  margin-bottom: 0px;
  color: #333333;
}
.header__userText {
  font-size: 11px;
  word-wrap: break-word;
  margin-bottom: 0px;
  color: #676767;
}

.header__logout button {
  outline: none;
}

.header .MuiButton-text {
  color: var(--brand-color);
  padding: 18px 0px;
  border-radius: 50%;
}

.MuiMenu-paper {
  max-height: calc(100% - 96px);
  margin-top: 50px !important;
}

.small-avatar {
  width: 24px;
  height: 24px;
  text-align: center;
  color: var(--brand-color);
  background: var(--brand-color-rgba);
  border-radius: 50%;
}

.header__menuPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.header_subHead {
  font-weight: bold;
  margin-bottom: 8px;
  color: #6b6b6b;
  font-size: 16px;
}

.header__newNotification {
  margin: 12px 0px;
  background: #f1f1f1;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.4s;
}

.header__newNotification:hover {
  background: #d6d6d6;
  transform: scale(1.02);
}

@media (max-width: 768px) {
  .header__title,
  .header__version {
    display: none;
  }
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  padding: 8px 8px 0px;
}

.header__notification {
  padding: 8px 16px;
  max-width: 300px !important;
}

.header__notificationText {
  color: #333333;
  background: #f3f3f3;
  padding: 8px;
  border-radius: 8px;
  transition: 0.2s;
}

.header__notificationText:hover {
  color: white;
  transform: scale(1.025);
  background: #609426;
}

.header__notification h4 {
  font-weight: bold;
  margin-bottom: 16px;
}

.header__notificationRecord {
  display: flex;
}

.header__notificationRecordActive {
  width: 28px;
  height: 28px;
}

.header__notificationRecordActive p {
  width: 8px;
  height: 8px;
  background: green;
  border-radius: 50%;
  position: relative;
  top: 12px;
}

.notifyBall {
  width: 6px;
  height: 6px;
  background: red;
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 50%;
}

.noNotification {
  width: 300px;
  height: 100px;
  display: grid;
  place-items: center;
  background: #f7f7f7;
  border-radius: 8px;
}

.makeStyles-avatar-4 {
  background-color: #609426 !important;
}

.height-12 {
  height: 12px;
}

.login__card {
  width: 33%;
  max-width: 560px;
  transition: 0.3s;
  background-color: white;
  padding: 26px 24px;
  border-radius: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;
  box-shadow: 0px 4px 20px 20px #f5f5f6;
}

@media (max-width: 981px) {
  .login__card {
    width: 626px;
  }
}

@media (max-width: 640px) {
  .login__card {
    width: 85%;
  }
}

@media (max-width: 400px) {
  .login__card {
    max-width: 350px;
  }
}

.button-wraper {
  display: grid;
  place-items: center;
}
.auth__container {
  text-align: center;
  margin-top: 32px;
}
.error-message {
  padding: 8px;
  text-align: center;
  transition: 0.4;
}

.MuiPaper-root.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8.MuiGrid-grid-md-4.MuiPaper-elevation0 {
  margin-top: 26px;
  margin-bottom: 70px;
  display: grid;
  place-items: center;
  border-radius: 12px;
}

.MuiPaper-root.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8.MuiGrid-grid-md-4.MuiPaper-elevation {
  background-image: url(http://vistaconcepts.co/wp-content/uploads/2021/04/vista-concepts-logo.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.97);
  background-blend-mode: overlay;
}

.messageBody__main{
    background: white;
    height: 100%;
    padding: 18px 22px;
    border-radius: 30px;
}

.messageBody__back {
    outline: none;
}

.messageBody__toolbar {
    padding: 16px 0;;
}

.messageBody__main .info {
    display: flex;
}

.messageBody__main .app__icon {
    margin-right: 12px;
}

.messageBody__main .info .info__field {
    display: flex;
    align-items: center;
    padding: 10px 24px 10px 0px;
    color: #666;
    font-size: 15px;
}
.sidebar {
  display: flex;
  flex-direction: column;
  background: #2e7d32;
  height: 100%;
  width: 270px;
  transition: 0.3s;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sidebar__infoContainer {
  background-image: linear-gradient(0deg, #3b7e33, #2b5a2d);
  padding: 12px 0 16px 0;
}

.sidebar_logoContainer img {
  display: block;
  margin: 0 auto;
  width: 84px;
}

.sidebar__profile {
  display: flex;
  align-items: center;
  background-color: white;
  margin: 12px 8px 0px;
  padding: 8px;
  border-radius: 8px;
}

.profile__loggedInfo {
  padding-left: 16px;
  padding-right: 16px;
}

.profile__userName {
  display: block;
  font-weight: bold;
  font-size: 14px;
  word-wrap: break-word;
  color: var(--brand-text-color-dark);
  white-space: nowrap;
  width: 144px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.profile__userText {
  display: block;
  font-size: 10px;
  word-wrap: break-word;
  margin-bottom: 0px;
  color: #505050;
  white-space: nowrap;
  width: 144px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar__closeIcon {
  position: absolute;
  right: 12px;
  color: white;
}

.sidebar.sidebar__closed {
  width: 0px;
}

.sidebar__menuTitle {
  flex: 1 1;
  color: #bdbdbd;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.8px;
  padding: 16px 18px;
}

@media (max-width: 1200px) {
  .sidebar {
    position: absolute;
    z-index: 110;
    height: 100vh;
    width: 220px;
    transition: 0.4s;
    box-shadow: -4px 8px 8px 4px #84848484;
  }
  .sidebar.sidebar__closed {
    position: relative;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.sideBarMenuItem {
  display: flex;
  align-items: center;
  padding: 6px 24px;
  transition: 0.2s;
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: bold;
}

.sideBarMenuItem__icon {
  color: #bbc7a9;
  transition: transform 0.8s !important;
}

.sideBarMenuItem__title {
  display: block;
  padding-left: 16px;
  font-size: 13px;
  transition: 0.4s;
  -webkit-animation: fade 0.8s;
          animation: fade 0.8s;
  color: #cfebd1;
  -webkit-animation: fade 0.8s ease-in;
}

.sidebar.sidebar__closed .sideBarMenuItem__title {
  display: none;
}

/* when hover */

.sideBarMenuItem:hover {
  background: white;
}

.sideBarMenuItem:hover .sideBarMenuItem__icon {
  transform: scale(1.2);
}

.sideBarMenuItem:hover .sideBarMenuItem__icon,
.sideBarMenuItem:hover .sideBarMenuItem__title {
  color: var(--brand-color);
}

/*  when active */

.active .sideBarMenuItem__icon,
.active .sideBarMenuItem__title {
  color: var(--brand-text-color-dark);
}

.active .sideBarMenuItem {
  background: white;
  border-right: 10px solid orange;
}

.signup__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 20px;
  height: 25px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 24px;
  padding-right: 24px;
  z-index: 20;
}

.signup__selectedChoiceContainer {
  display: flex;
  flex-direction: column;
}

@media (max-width: 420px) {
  .signup__selectedChoiceContainer span {
    font-size: 9px !important;
  }
}

.signup__headerRight {
  display: flex;
  align-items: center;
}

.signup__container h2 {
  padding: 24px;
}

.signup__card {
  width: 33%;
  transition: 0.3s;
  background-color: white;
  padding: 26px 24px;
  border-radius: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (max-width: 981px) {
  .signup__card {
    width: 626px;
  }
}

@media (max-width: 640px) {
  .signup__card {
    width: 85%;
  }
}

@media (max-width: 400px) {
  .signup__card {
    max-width: 350px;
  }
}

.signup__choiceList {
  display: flex;
}

.signup__choiceItem {
  max-width: 300px;
  min-width: 280px;
  height: 100px;
  padding: 24px;
  margin: 12px;
  border-radius: 12px;
  background: white;
  border: 1px solid white;
  transition: 0.2s;
  cursor: pointer;
}

.signup__choiceItem h3 {
  font-size: 1.5rem;
  color: var(--brand-color);
  font-weight: bold;
}

.signup__choiceItem p {
  color: grey;
}

.signup__choiceItem:hover {
  background: #609426;
  /* transform: scale(1.02); */
  border: 1px solid var(--brand-color);
}

.signup__choiceItem:hover p {
  color: #fafafa;
}

.signup__choiceItem:hover h3 {
  color: white;
}

@media (max-width: 981px) {
  .signup__choiceList {
    flex-direction: column;
  }
}

.dashboard__overview {
  display: grid;
  grid-gap: 0.6rem;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  margin: 8px 0 24px 0;
}

.dashboard__overviewCard {
  text-align: center;
  box-shadow: 2px 2px 10px 5px #f1f1f1;
  padding: 16px;
  border-radius: 8px;
  background: white;
  margin-right: 24px;
  margin-bottom: 24px;
}

.dashboard__overviewCard span {
  font-weight: bold;
  font-size: 2.2rem;
  line-height: 1.8;
  color: var(--brand-color);
}

.dashboard__overviewCard p {
  color: #585858;
}

.dashboard__timeLine {
  flex: 1 1;
  background: white;
  padding: 24px;
  border-radius: 8px;
  margin-right: 24px;
}

.dashboard__rejection {
  flex: 1 1;
  background: white;
  padding: 24px;
  border-radius: 8px;
  margin-right: 24px;
}

.dashboard__timeLineEvent {
  display: flex;
}

.dashboard__timelinePoint {
  width: 28px;
  min-height: 36px;
  position: relative;
}

.dashboard__timelineContent {
  padding-top: 6px;
}

.dashboard__timelineContent div {
  padding-left: 24px;
  color: #949494;
}

.dashboard__timelineContent div div span {
  line-height: 2;
}

.dashboard__timelinePointCircle {
  padding: 4px;
  border-radius: 50%;
  width: 2px;
  height: 2px;
  background: #bdc5b3;
  position: absolute;
  top: 12px;
}

.dashboard__timelinePointLine {
  height: 100%;
  width: 2px;
  background: #bdc5b3;
  position: relative;
  left: 4px;
}

.dashboard__statistics {
  display: flex;
  flex-direction: row;
}

@media (max-width: 700px) {
  .dashboard__statistics {
    flex-direction: column;
  }
  .dashboard__statistics .dashboard__timeLine,
  .dashboard__statistics .dashboard__rejection {
    margin-top: 32px;
  }
}

.dashboard__timlineH6 {
  color: var(--brand-color);
  font-weight: bold;
}

span.dashboard__counterHighlight {
  padding: 2px 12px;
  border-radius: 4px;
  font-size: 18px;
  margin-right: 12px;
}

.dashboard__payment {
  display: flex;
}

@media (max-width: 768px) {
  .dashboard__payment {
    flex-direction: column;
  }
}

.dashboard__paymentPricingCard {
  flex: 1 1;
  text-align: center;
  box-shadow: 2px 2px 10px 5px #f1f1f1;
  padding: 32px;
  border-radius: 8px;
  background: white;
  margin-right: 24px;
  margin-bottom: 24px;
}
.dashboard__paymentPriceAmount {
  font-size: 48px;
  color: var(--brand-color);
  font-weight: bold;
}
.dashboard__paymentDiscountAmount {
  color: #949494;
}

.dashboard__payButton {
  width: 70% !important;
}

.dashboard__continuePayment {
  width: min(450px, 90%) !important;
  display: inline !important;
}

.dashboard__paypalButtons {
  width: 55%;
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
}

.dashboard__table {
  width: 100%;
  border: 1px solid black !important;
}

.dashboard__table tbody {
  background-color: rgb(255, 255, 255);
}

.dashboard__table td {
  padding: 12px 32px;
  border: 1px solid rgb(216, 216, 216);
}

.dashboard__table tr:last-child td,
.dashboard__table tr:last-child td span {
  font-size: 18px;
  color: #609426;
  font-weight: bold;
}

.dashboard__table tr:last-child td {
  font-size: 14px;
  color: #3d3f3d;
  font-weight: bold;
}

.dashboard__table tr:last-child {
  background-color: #f1f9ef;
}

.dashboard__table td:nth-child(2) {
  text-align: center;
}

.circularProgressBar {
  display: grid;
  place-items: center;
  height: 100%;
}

.circularProgressBar .MuiTypography-colorTextSecondary {
  font-size: 24px;
  font-weight: bold;
  color: red;
}

.circularProgressBarCircle {
  border: 12px solid #fdfdfd !important;
  box-shadow: inset 0px 0px 0px 12px #cecece;
  color: #ff7575 !important;
  border-radius: 50%;
}

.bg-finished {
  color: white;
  background-color: #57bbbb;
}

.bg-approved {
  color: white;
  background-color: rgb(26, 177, 58);
}

.bg-queue {
  color: white;
  background-color: rgb(153, 0, 255);
}

