.messageBody__main{
    background: white;
    height: 100%;
    padding: 18px 22px;
    border-radius: 30px;
}

.messageBody__back {
    outline: none;
}

.messageBody__toolbar {
    padding: 16px 0;;
}

.messageBody__main .info {
    display: flex;
}

.messageBody__main .app__icon {
    margin-right: 12px;
}

.messageBody__main .info .info__field {
    display: flex;
    align-items: center;
    padding: 10px 24px 10px 0px;
    color: #666;
    font-size: 15px;
}