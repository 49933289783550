.sidebar {
  display: flex;
  flex-direction: column;
  background: #2e7d32;
  height: 100%;
  width: 270px;
  transition: 0.3s;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sidebar__infoContainer {
  background-image: linear-gradient(0deg, #3b7e33, #2b5a2d);
  padding: 12px 0 16px 0;
}

.sidebar_logoContainer img {
  display: block;
  margin: 0 auto;
  width: 84px;
}

.sidebar__profile {
  display: flex;
  align-items: center;
  background-color: white;
  margin: 12px 8px 0px;
  padding: 8px;
  border-radius: 8px;
}

.profile__loggedInfo {
  padding-left: 16px;
  padding-right: 16px;
}

.profile__userName {
  display: block;
  font-weight: bold;
  font-size: 14px;
  word-wrap: break-word;
  color: var(--brand-text-color-dark);
  white-space: nowrap;
  width: 144px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.profile__userText {
  display: block;
  font-size: 10px;
  word-wrap: break-word;
  margin-bottom: 0px;
  color: #505050;
  white-space: nowrap;
  width: 144px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar__closeIcon {
  position: absolute;
  right: 12px;
  color: white;
}

.sidebar.sidebar__closed {
  width: 0px;
}

.sidebar__menuTitle {
  flex: 1;
  color: #bdbdbd;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.8px;
  padding: 16px 18px;
}

@media (max-width: 1200px) {
  .sidebar {
    position: absolute;
    z-index: 110;
    height: 100vh;
    width: 220px;
    transition: 0.4s;
    box-shadow: -4px 8px 8px 4px #84848484;
  }
  .sidebar.sidebar__closed {
    position: relative;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
