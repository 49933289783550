.signup__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 20px;
  height: 25px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 24px;
  padding-right: 24px;
  z-index: 20;
}

.signup__selectedChoiceContainer {
  display: flex;
  flex-direction: column;
}

@media (max-width: 420px) {
  .signup__selectedChoiceContainer span {
    font-size: 9px !important;
  }
}

.signup__headerRight {
  display: flex;
  align-items: center;
}

.signup__container h2 {
  padding: 24px;
}

.signup__card {
  width: 33%;
  transition: 0.3s;
  background-color: white;
  padding: 26px 24px;
  border-radius: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (max-width: 981px) {
  .signup__card {
    width: 626px;
  }
}

@media (max-width: 640px) {
  .signup__card {
    width: 85%;
  }
}

@media (max-width: 400px) {
  .signup__card {
    max-width: 350px;
  }
}

.signup__choiceList {
  display: flex;
}

.signup__choiceItem {
  max-width: 300px;
  min-width: 280px;
  height: 100px;
  padding: 24px;
  margin: 12px;
  border-radius: 12px;
  background: white;
  border: 1px solid white;
  transition: 0.2s;
  cursor: pointer;
}

.signup__choiceItem h3 {
  font-size: 1.5rem;
  color: var(--brand-color);
  font-weight: bold;
}

.signup__choiceItem p {
  color: grey;
}

.signup__choiceItem:hover {
  background: #609426;
  /* transform: scale(1.02); */
  border: 1px solid var(--brand-color);
}

.signup__choiceItem:hover p {
  color: #fafafa;
}

.signup__choiceItem:hover h3 {
  color: white;
}

@media (max-width: 981px) {
  .signup__choiceList {
    flex-direction: column;
  }
}
