:root {
  --brand-color: #609426;
  --brand-color-rgba: rgba(96, 148, 38, 0.3);
  --brand-text-color-dark: #3f6714;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;1,400&display=swap');

* {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif !important;
  outline: none !important;
}

.App {
  display: flex;
  height: 100vh;
}

.app__body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.app__icon {
  color: var(--brand-color);
}

h2 {
  color: #333333 !important;
}

.centerLoader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.centerItem {
  display: grid;
  place-items: center;
  width: 100%;
  text-align: center;
}

*,
*::before,
*::after {
  box-sizing: unset !important;
}

.u-text {
  color: #777777;
}

.u-head-text {
  color: #3e3e3e;
}

.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary,
.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  outline: none;
  color: black;
  font-weight: 600;
  text-align: center;
  display: flex;
  margin: 0 auto;
  height: 32px;
  width: 180px;
}

.MuiButton-contained {
  background-color: #609426 !important;
  border-radius: 6px !important;
  color: #fff !important;
}

.MuiButton-outlined {
  border-radius: 6px !important;
  color: #609426 !important;
  font-weight: bold !important;
  border: 2px solid #609426;
}

.MuiFormLabel-root.Mui-focused {
  padding: 4px 0px !important;
}

.u-animate-fade {
  animation: showfade 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s forwards 1;
}

.u-animate-slideUp {
  animation: showSlideUp 0.6s ease-in 0s forwards 1;
}

.u-animate-slideDown {
  animation: showSlideDown 0.6s ease-in 0s forwards 1;
}

.u-animate-slideLeft {
  animation: showSlideLeft 0.3s ease-in 0s forwards 1;
}

@keyframes showSlideUp {
  0% {
    transform: translateY(3%);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes showSlideDown {
  0% {
    transform: translateY(-3%);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes showSlideLeft {
  0% {
    transform: translateX(3%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes showfade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dropzone:hover {
  background-size: 30px 30px;
  background-image: -webkit-linear-gradient(
    135deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    -45deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  );
  -webkit-animation: stripes 2s linear infinite;
  animation: stripes 2s linear infinite !important;
}

@keyframes stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 30px;
  }
}

.ListTile {
  background: white;
  transition: 0.2s;
}

.ListTile:hover {
  background: #f9f9f9;
}

.u-small-dialog .MuiPaper-root {
  width: min(100%, 380px);
}

.u-medium-dialog .MuiPaper-root {
  width: min(100%, 500px);
}

.u-large-dialog .MuiPaper-root {
  width: min(100%, 600px);
}

.rowProject__Title {
  font-weight: bold;
  font-size: 12px;
  color: #609426;
  transition: 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.rowProject__Title span {
  font-size: 12px;
}

.rowProject__Title:hover {
  color: #426619;
}

.toast-header {
  color: white !important;
  background-color: #609426 !important;
}

#nprogress .bar {
  background: #609426 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #609426, 0 0 5px #609426 !important;
}

#nprogress .spinner-icon {
  border-top-color: #609426 !important;
  border-left-color: #609426 !important;
}
