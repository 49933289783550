.sideBarMenuItem {
  display: flex;
  align-items: center;
  padding: 6px 24px;
  transition: 0.2s;
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: bold;
}

.sideBarMenuItem__icon {
  color: #bbc7a9;
  transition: transform 0.8s !important;
}

.sideBarMenuItem__title {
  display: block;
  padding-left: 16px;
  font-size: 13px;
  transition: 0.4s;
  animation: fade 0.8s;
  color: #cfebd1;
  -webkit-animation: fade 0.8s ease-in;
}

.sidebar.sidebar__closed .sideBarMenuItem__title {
  display: none;
}

/* when hover */

.sideBarMenuItem:hover {
  background: white;
}

.sideBarMenuItem:hover .sideBarMenuItem__icon {
  transform: scale(1.2);
}

.sideBarMenuItem:hover .sideBarMenuItem__icon,
.sideBarMenuItem:hover .sideBarMenuItem__title {
  color: var(--brand-color);
}

/*  when active */

.active .sideBarMenuItem__icon,
.active .sideBarMenuItem__title {
  color: var(--brand-text-color-dark);
}

.active .sideBarMenuItem {
  background: white;
  border-right: 10px solid orange;
}
